<template>
  <div class="d-flex align-items-center">
    <div class="h2 m-0 p-0 font-weight-normal"
         v-b-tooltip.hover="`${currentDate} ${currentTime}`">
      <!-- <i class="flaticon-clock-1" /> -->
      {{ currentTime }}
    </div>
    <!-- <div>{{currentDate}}</div> -->
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      currentTime: "",
      currentDate: "",
    };
  },
  methods: {
    updateTime() {
      this.currentTime = moment().format("HH:mm:ss");
      this.currentDate = moment().format("GG.MM.YYYY");
    },
  },
  mounted() {
    // Sayfa yüklendiğinde ve her saniye güncellenmesini istediğimiz için setInterval kullanıyoruz.
    this.updateTime();
    this.timer = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    // Bileşen yok edildiğinde setInterval'i temizlememiz gerekiyor.
    clearInterval(this.timer);
  },
};
</script>